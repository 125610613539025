import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import PostersSignageImg from "../../components/images/printing/posters"

export default function PostersSignage() {
    return (
        <>
            <Layout>
                <SEO title="Posters & Signage" />
                <Display
                    title="Posters & Signage"
                    description="We are printing specialists - looking after all of your home or business needs, at prices that won’t break the bank. Connect today to get a quote."
                    prev="/printing/flyers"
                    next="/printing/business-cards"
                    image={<PostersSignageImg />}
                />
            </Layout>                
        </>
    )
}
